import * as React from "react";
import Box from "@mui/joy/Box";
//import Button from '@mui/joy/Button';
import { Button } from "@mui/material";
import Divider from "@mui/joy/Divider";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
//import DeleteForever from '@mui/icons-material/DeleteForever';
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Typography from "@mui/joy/Typography";
import { useState, Fragment, useEffect } from "react";
import { AiFillPrinter } from "react-icons/ai";

import { useCustomer } from "../Context/CustomerContext";
import { useUser } from "../Context/UserContext";
import { useLieferscheinContext } from "../Context/LieferscheinContext";

import { FormText } from "react-bootstrap";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import "./print.css";

export default function PrintLieferschein() {
  const [open, setOpen] = useState(false);

  const { token } = useUser();
  const { listData } = useCustomer();
  const { listLieferscheinNummer } = useLieferscheinContext();
  //console.log("List Lieferscheinsnummer", listLieferscheinNummer);

  const currentLieferscheinsNummer = Math.max(
    ...listLieferscheinNummer.map((n) => n.lieferscheinNummerNew)
  );
  console.log("Aktuel Lieferscheinsnummer: ", currentLieferscheinsNummer);

  const [listKunden, setListKunden] = useState([]);

  let kunden = listKunden;

  const firmenMap = kunden?.map((customer) => {
    return customer;
  });
  const filteredData = firmenMap?.filter((obj) => {
    return obj.lieferscheins.find(
      (object) => object.lieferscheinNummer === `${currentLieferscheinsNummer}`
    );
  });
  const findNummer = filteredData?.map(({ lieferscheins, i }) => {
    return lieferscheins;
  });
  const flatNummer = findNummer?.flat();

  function search(array, condition) {
    if (array.length === 0) {
      return [];
    }
    const [head, ...tail] = array;
    if (condition(head)) {
      return [head, ...search(tail, condition)];
    }
    return search(tail, condition);
  }

  const searchLieferscheins = search(
    flatNummer,
    (nummer) => nummer?.lieferscheinNummer === `${currentLieferscheinsNummer}`
  );

  console.log(
    "Firmen:",
    firmenMap,
    "Filter:",
    filteredData,
    "Flat:",
    flatNummer,
    "Find: ",
    findNummer,
    "Search: ",
    searchLieferscheins
  );

  useEffect(() => {
    setListKunden(listData);
  }, [listData]);

  const findLieferschein = filteredData?.find(
    (lieferscheins) =>
      lieferscheins.lieferscheinNummer === `${currentLieferscheinsNummer}`
  );
  let listLieferscheins = findLieferschein?.lieferscheins;
  console.log("Lieferscheins: ", listLieferscheins);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      window.location.reload(false);
    }, 300);
  };

  const Print = () => {
    let printContents = document.getElementById("print").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    handleClose();
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(price);
  };

  const isLogout = () => {
    setTimeout(() => {
      window.location.reload(false);
    }, 350);
    window.location.href = "/";
};

  return (
    <>
      {token ? (
        <>
          <Fragment>
            <Button
              variant="contained"
              color="success"
              sx={{
                height: 40,
              }}
              onClick={() => setOpen(true)}
            >
              <AiFillPrinter />
              &nbsp;Drucken
            </Button>
            <Modal open={open} onClose={() => setOpen(false)}>
              <ModalDialog
                variant="outlined"
                role="alertdialog"
                aria-labelledby="alert-dialog-modal-title"
                aria-describedby="alert-dialog-modal-description"
              >
                {filteredData.map((data, i) => (
                  <Box
                    sx={{
                      // bgcolor: "#EAEDF0",
                      // height: "75vh",
                      maxHeight: "100%",
                      minHeight: "75vh",
                      // padding: "1rem",
                      minWidth: "50vw",
                      //minHeight: "50vh",
                      padding: "2cm",
                      margin: "1cm auto",
                      border: "1px #D3D3D3 solid",
                      borderRadius: "5px",
                      background: "white",
                      boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                      
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Box variant="soft" sx={{ py: 0.4 }} id="print">
                        <div className="d-flex flex-row justify-content-between">
                          <div className="d-flex flex-column">
                            <Typography fontSize="12px">Öz Güven Warenhandels GmbH</Typography>
                            <Typography fontSize="12px">
                              Keplerstr. 13/15, 50823 Köln
                            </Typography>
                          </div>
                          <div className="d-flex flex-column">
                            <Typography fontSize="10px">Tel: 0221/562163</Typography>
                            <Typography fontSize="10px">Fax. 0221/525479</Typography>
                          </div>
                        </div>
                        <hr />
                        <h2>Lieferschein</h2>
                        <Box className="d-flex p-1 justify-content-between" fontSize="10px">
                          <Box className="d-flex flex-column">
                            <Typography level="body1">Kundenangaben</Typography>
                            <FormText key={i}>
                              {/* <Typography component="b">Firma: </Typography> */}
                              {filteredData !== undefined ? (
                                <b>{data.hitab} </b>
                              ) : (
                                " An das"
                              )}
                              {filteredData !== undefined ? (
                                <b> {data.ismi}</b>
                              ) : (
                                " Name"
                              )}
                            </FormText>

                            <FormText key={i}>
                              {filteredData !== undefined ? (
                                <b>{data.cadde}</b>
                              ) : (
                                " Straße & Nummer"
                              )}
                            </FormText>
                            {/* <FormText>
                            {listLieferscheins !== undefined ? (
                              <b>{listLieferscheins}</b>
                            ) : (
                              " Straße & Nummer"
                            )}
                          </FormText> */}

                            <FormText key={i}>
                              {filteredData !== undefined ? (
                                <b>{data.plz} </b>
                              ) : (
                                " PLZ"
                              )}
                              {filteredData !== undefined ? (
                                <b>{data.yer} </b>
                              ) : (
                                " Stadt"
                              )}
                            </FormText>
                          </Box>
                          <Box className="d-flex flex-column padding-right-6 w-35">
                            <Typography level="body1" key={i}>
                              Lieferschein -Nr.:{" "}
                              {filteredData !== undefined ? (
                                <b>{currentLieferscheinsNummer}</b>
                              ) : (
                                "Nummer"
                              )}
                            </Typography>
                            <FormText key={i}>
                              Kundennummer:{" "}
                              {filteredData !== undefined ? (
                                <b>{data.kodu} </b>
                              ) : (
                                "none"
                              )}
                            </FormText>
                            {flatNummer.map((lieferscheinData, k) =>
                              lieferscheinData.lieferscheinNummer ===
                              `${currentLieferscheinsNummer}` ? (
                                <>
                                  <FormText key={k}>
                                    Datum:{" "}
                                    {filteredData !== undefined ? (
                                      <b>
                                        {lieferscheinData?.lieferscheinDatum}{" "}
                                      </b>
                                    ) : (
                                      "none"
                                    )}
                                  </FormText>
                                  <FormText key={k}>
                                    Leistungsdatum:{" "}
                                    {filteredData !== undefined ? (
                                      <b>{lieferscheinData?.leistungDatum} </b>
                                    ) : (
                                      "none"
                                    )}
                                  </FormText>
                                  <FormText key={k}>
                                    Lieferant:{" "}
                                    {filteredData !== undefined ? (
                                      <b>{lieferscheinData?.lieferant} </b>
                                    ) : (
                                      "none"
                                    )}
                                  </FormText>
                                </>
                              ) : null
                            )}
                          </Box>
                        </Box>
                        <Typography fontSize="10px">
                          Sehr geehrte Damen und Herren,
                          <br />
                          vielen Dank für die Zusammenarbeit.
                          Vereinbarungsgemäß liefern wir Ihnen folgende Waren:
                        </Typography>
                        <hr />
                        <Box sx={{ width: "100%"}}>
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: "50vw"}}
                              aria-label="caption table"
                              
                            >
                              <caption  style={{ fontSize: "7px", padding: "5px", paddingLeft: "10px" }}>Ware ordnungsgemäß erhalten</caption>
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left" sx={{ fontSize: "10px", padding: "2px", paddingLeft: "10px", fontWeight: 500 }}>Kodu</TableCell>
                                  <TableCell align="left" sx={{ fontSize: "10px", padding: "2px" }}>
                                    Artikel Name
                                  </TableCell>
                                  <TableCell align="right" sx={{ fontSize: "10px", padding: "2px" }}>Menge</TableCell>
                                  <TableCell align="right" sx={{ fontSize: "10px", padding: "2px" }}>Price</TableCell>
                                  <TableCell align="right" sx={{ fontSize: "10px", padding: "2px" }}>Zutaten:</TableCell>
                                  <TableCell align="right" sx={{ fontSize: "10px", padding: "2px" }}>Kistenanzahl</TableCell>
                                  <TableCell align="right" sx={{ fontSize: "10px", padding: "2px", paddingRight: "10px" }}>Retour</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {flatNummer?.map((lieferscheinData, l) =>
                                  lieferscheinData.lieferscheinNummer ===
                                    `${currentLieferscheinsNummer}` ? (
                                      (lieferscheinData?.lieferscheinArtikelsDb?.map((artikel) => 
                                      <TableRow key={l}>
                                      <TableCell component="th" scope="row" sx={{ fontSize: "10px", padding: "2px", paddingLeft: "10px" }}>
                                        {artikel.inputArtikelKoduIn}
                                      </TableCell>
                                      <TableCell align="left" sx={{ fontSize: "10px", padding: "2px" }}>
                                        {artikel.inputArtikelNameIn}
                                      </TableCell>
                                      <TableCell align="right" sx={{ fontSize: "10px", padding: "2px" }}>
                                        {artikel.inputArtikelMengeIn} Stk.
                                      </TableCell>
                                      <TableCell align="right" sx={{ fontSize: "10px", padding: "2px" }}>
                                        {formatPrice(
                                          artikel.inputArtikelPriceIn
                                        )}
                                          </TableCell>
                                          <TableCell align="right" sx={{ fontSize: "10px", padding: "2px" }}>
                                        {artikel.inputArtikelBeschreibungIn}
                                      </TableCell>
                                      <TableCell align="right" sx={{ fontSize: "10px", padding: "2px" }}>
                                        {artikel.inputArtikelKistenIn} Stk.
                                          </TableCell>
                                          <TableCell align="right" sx={{ fontSize: "10px", padding: "2px", paddingRight: "10px" }}>
                                        {" "}
                                      </TableCell>
                                    </TableRow>
                                  ))) : null
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    justifyContent: "flex-end",
                    pt: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    color="success"
                    sx={{
                      height: 40,
                    }}
                    onClick={Print}
                  >
                    <AiFillPrinter />
                    &nbsp;Drucken
                  </Button>
                  <Button variant="plain" color="danger" onClick={handleClose}>
                    <WarningRoundedIcon />
                    &nbsp;Cancel
                  </Button>
                </Box>
              </ModalDialog>
            </Modal>
          </Fragment>
        </>
      ) : isLogout()}
    </>
  );
}
