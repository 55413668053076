import React, { Fragment, useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, Container } from "@mui/system";
import Typography from "@mui/joy/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/joy/FormControl";
import { Button, TextField } from "@mui/material";

import DatePicker from "react-date-picker";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { useCustomer } from "../Context/CustomerContext";
import { useUser } from "../Context/UserContext";
import { FormLabel } from "@mui/joy";
import { FormText } from "react-bootstrap";

import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { AiFillPrinter } from "react-icons/ai";
import { MdDeleteForever } from "react-icons/md";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";


export default function CollectiveInvoice() {
  const HeuteDatum = () => {
    const date = new Date();
    const heute =
      String(date.getDate()).padStart(2, "0") +
      "." +
      String(date.getMonth() + 1).padStart(2, "0") +
      "." +
      date.getFullYear();
    return <strong>{heute}</strong>;
  };

  const { token } = useUser();

  const { listData } = useCustomer();
  console.log("List Costumers:", listData);

  const [listKunden, setListKunden] = useState([]);

  useEffect(() => {
    setListKunden(listData);
  }, [listData]);

  let kunden = listKunden;
  console.log("Kunden:", kunden);

  const firmenMap = kunden.map(({ ismi }) => ismi);
  console.log("FirmenMap: ", firmenMap);

  const [value, setValue] = useState("Firma");
  const [inputValue, setInputValue] = useState("");

  const Find = kunden.find((firma) => firma.ismi === `${value}`);
  console.log("Find: ", Find);

  const findLieferschein = kunden?.find(
    (lieferschein) => lieferschein.ismi === `${value}`
  );
  let listLieferscheins = findLieferschein?.lieferscheins.flat();
  console.log("list Lieferschein", listLieferscheins);
  

  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());

  // const LeistungsDatum = () => {

  //   const dateStart = new Date(startDate);
  //   const start =
  //     String(dateStart.getDate()).padStart(2, "0") +
  //     "." +
  //     String(dateStart.getMonth() + 1).padStart(2, "0") +
  //     "." +
  //     dateStart.getFullYear();
  //   const dateEnd = new Date(endDate);
  //   const ende =
  //     String(dateEnd.getDate()).padStart(2, "0") +
  //     "." +
  //     String(dateEnd.getMonth() + 1).padStart(2, "0") +
  //     "." +
  //     dateEnd.getFullYear();
    

    
  //   //console.log("Start Date:", startDate, "End Date: ",endDate, "Leistung: ", );
  //     return (
  //       <>
  //         <Box
  //           sx={{
  //             display: "flex",
  //             flexDirection: "row",
  //             justifyContent: "space-between",
  //           }}
  //         >
  //           <Box sx={{display: "flex", gap: 3}}>
  //             <DatePicker
  //               value={startDate}
  //               onChange={(date) => setStartDate(date)}
  //             />
  //             <DatePicker
  //               value={endDate}
  //               onChange={(daten) => setEndDate(daten)}
  //             />
  //           </Box>
  //           <FormText style={{paddingRight: "6rem"}}>
  //             Leistungszeitraum: {start} - {ende}
  //           </FormText>
  //         </Box>
  //       </>
  //     );
  // };

  const [products, setProducts] = useState([]);
 // const [allProducts, setAllProducts] = useState([]);
   const [startDate,setStartDate]= useState(new Date());
   const [endDate,setEndDate]= useState(new Date());
// useEffect(() => {
//   setAllProducts(listLieferscheins)
// }, [])
  
const handleSelect = (date, startDate, endDate) =>{
  const filtered = listLieferscheins?.filter(item => {
    const itemDate = new Date(item["createdAt"]);
    return (!startDate || itemDate >= startDate) && (!endDate || itemDate <= endDate);
  });
    setStartDate(date.selection.startDate);
    setEndDate(date.selection.endDate);
    setProducts(filtered);
  };
  console.log("Produckts:", products);

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: 'selection',
  }

  console.log("Start Date:", startDate, "End Date: ", endDate, "Leistung: ",);
  
  const Print = () => {
    let printContents = document.getElementById("print").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(price);
  };

  const isLogout = () => {
    setTimeout(() => {
      window.location.reload(false);
    }, 350);
    window.location.href = "/";
  }

  return token ? (
    <Fragment>
      <CssBaseline />
      <Container maxWidth="xl">
        <br />
        <Box sx={{ bgcolor: "#EAEDF0", height: "70vh", padding: "1rem" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Box variant="soft" sx={{ py: 0.4 }}>
              {/* <code>
                  <strong>{`${
                    value !== null ? `'${value}'` : "Firma"
                  }`}</strong>
                </code> */}
              <h1>Rechnung</h1>
              <Box className="d-flex p-3 justify-content-between">
                <Box className="d-flex flex-column">
                  <Typography level="body1">Kundenangaben</Typography>
                  <FormText>
                    {/* <Typography component="b">Firma: </Typography> */}
                    {Find !== undefined ? <b>{Find.hitab} </b> : " An das"}
                    {Find !== undefined ? <b> {Find.ismi}</b> : " Name"}
                  </FormText>
                  <FormText>
                    {Find !== undefined ? (
                      <b>{Find.cadde}</b>
                    ) : (
                      " Straße & Nummer"
                    )}
                  </FormText>
                  <FormText>
                    {Find !== undefined ? (
                      <b>{Find.kisi}</b>
                    ) : (
                      " Straße & Nummer"
                    )}
                  </FormText>
                  <FormText>
                    {Find !== undefined ? <b>{Find.plz} </b> : " PLZ"}
                    {Find !== undefined ? <b>{Find.yer} </b> : " Stadt"}
                  </FormText>
                </Box>
                <Box className="d-flex flex-column padding-right-6 w-25">
                  <Typography level="body1">
                    Rechnungsnummer 012225325
                  </Typography>
                  <FormText>
                    Kundennummer{" "}
                    {Find !== undefined ? <b>{Find.kodu} </b> : "none"}
                  </FormText>
                  <FormText>
                    Datum:
                    <HeuteDatum />
                  </FormText>
                  <br />
                </Box>
              </Box>
              {/* <LeistungsDatum /> */}
              <hr />
            </Box>
          </Box>
          <br />
          <Box sx={{ height: "50%", width: "100%" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: "50vw" }} aria-label="caption table">
                <caption
                  style={{
                    fontSize: "7px",
                    padding: "5px",
                    paddingLeft: "10px",
                  }}
                >
                  Ware ordnungsgemäß erhalten
                </caption>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "10px",
                        padding: "2px",
                        paddingLeft: "10px",
                        fontWeight: 500,
                      }}
                    >
                      Id 
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ fontSize: "10px", padding: "2px" }}
                    >
                      Artikel Name
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ fontSize: "10px", padding: "2px" }}
                    >
                      Zutaten:
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontSize: "10px", padding: "2px" }}
                    >
                      Menge
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontSize: "10px", padding: "2px" }}
                    >
                      Preis (€)
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontSize: "10px", padding: "2px" }}
                    >
                      Rabatt (%)
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontSize: "10px",
                        padding: "2px",
                        paddingRight: "35px",
                      }}
                    >
                      Gesamt
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listLieferscheins?.map((lieferscheinData, l) =>
                    lieferscheinData.leistungDatum ===
                    `${startDate}`
                      ? lieferscheinData?.lieferscheinArtikelsDb?.map(
                          (artikel) => (
                            <TableRow key={1}>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  fontSize: "10px",
                                  padding: "2px",
                                  paddingLeft: "10px",
                                }}
                              >
                                {artikel.inputArtikelKoduIn}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ fontSize: "10px", padding: "2px" }}
                              >
                                {artikel.inputArtikelNameIn}
                              </TableCell>
                              <TableCell
                                align="right"
                                sx={{ fontSize: "10px", padding: "2px" }}
                              >
                                {artikel.inputArtikelMengeIn} Stk.
                              </TableCell>
                              <TableCell
                                align="right"
                                sx={{ fontSize: "10px", padding: "2px" }}
                              >
                                {/* {formatPrice(artikel.inputArtikelPriceIn)} */}
                              </TableCell>
                              <TableCell
                                align="right"
                                sx={{ fontSize: "10px", padding: "2px" }}
                              >
                                {/* {artikel.inputArtikelBeschreibungIn} */}
                              </TableCell>
                              <TableCell
                                align="right"
                                sx={{ fontSize: "10px", padding: "2px" }}
                              >
                                {/* {artikel.inputArtikelKistenIn} Stk. */}
                              </TableCell>
                              <TableCell
                                align="right"
                                sx={{
                                  fontSize: "10px",
                                  padding: "2px",
                                  paddingRight: "10px",
                                }}
                              >
                                {" "}
                              </TableCell>
                            </TableRow>
                           )
                        )
                      : null
                  )} 
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        <div className="App">
      <header className="App-header">
      <DateRangePicker
        ranges={[selectionRange]}
        onChange={handleSelect}
      />
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Product</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            
              {products?.map((product)=>{
                let date = new Date(product["createdAt"]);
                return(
                  <tr>
                    <td>{product["id"]}</td>
                    <td>{product["lieferant"]}</td>
                    <td>{date.toLocaleDateString()}</td>
                  </tr>
                );
              })}
            
          </tbody>
        </table>
      </header>
    </div>
        <FormControl id="controllable" sx={{ marginTop: "1rem" }}>
          <FormLabel>Hier eine Firma wählen</FormLabel>
          <Autocomplete
            placeholder="Müsteri ara"
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            key={firmenMap}
            options={firmenMap}
            sx={{ width: 350, zIndex: 30 + "!important", borderRadius: 50 }}
            renderInput={(params) => (
              <TextField {...params} label="Ismiyle | Müsteri ara" />
            )}
          />
        </FormControl>
        <div className="d-flex flex-wrap justify-content-around">
          <div className="d-flex flex-wrap mt-3 gap-3">
            <Button
              variant="contained"
              sx={{
                height: 40,
              }}
            >
              <BsFillFileEarmarkPdfFill />
              &nbsp;Save to PDF
            </Button>
            <Button
              variant="outlined"
              sx={{
                height: 40,
              }}
            >
              Send to Mail
            </Button>
          </div>
          <div className="d-flex flex-wrap mt-3 gap-3">
            <Button
              variant="contained"
              color="success"
              sx={{
                height: 40,
              }}
            >
              <AiFillPrinter />
              &nbsp;Print
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{
                height: 40,
              }}
            >
              <MdDeleteForever />
              &nbsp;Delete
            </Button>
          </div>
        </div>
      </Container>
    </Fragment>
  ) : (
    <div>
        <h1>Du bist nicht angemeldet!</h1>
        {isLogout()}
    </div>
  );
}
